document.callOnReady(() => {
  $(".take-quiz-container")
    .parent()
    .one("mouseover", () => {
      document.dispatchEvent(new Event("quiz:started"));
    });

  $(".take-quiz-container form").submit(() => {
    document.dispatchEvent(new Event("quiz:submitted"));

    return true;
  });
});
