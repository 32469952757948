document.callOnReady(function () {
  $("#add-github-username").click(function () {
    $("#add-github-username").hide();
    $("#github-username-form").show();
  });

  $("#github-username-form .button-grey").click(function (e) {
    e.preventDefault();
    $("#add-github-username").show();
    $("#github-username-form").hide();
  });

  if (
    document.getElementById("progress-graph") &&
    typeof progressGraphData != "undefined"
  ) {
    $("#progress-graph").github_graph({
      data: progressGraphData,
      texts: progressGraphTexts,
      colors: [
        "#e9eaed",
        "#9eedc5",
        "#86e3b4",
        "#72daa3",
        "#5fcd8d",
        "#59c882",
      ],
    });
  }

  const sparklineConfig = {
    type: "line",
    lineColor: "#59c680",
    fillColor: "#d8f5e6",
    width: "100%",
    height: "42px",
    disableInteraction: true,
    spotColor: "",
    minSpotColor: "",
    maxSpotColor: "",
    lineWidth: 2,
  };

  if (typeof sparklineData != "undefined") {
    for (const id in sparklineData) {
      if (document.getElementById(id)) {
        $(`#${id}`).sparkline(sparklineData[id], sparklineConfig);
      }
    }
  }

  const container = document.getElementById(
    "sparkline-container-in-code-editor",
  );
  if (container) {
    $.get(
      "/api/metrics/code_editor_grouped_by_day",
      { user_id: container.dataset.userId },
      (data) => {
        const duration = data.reduce((acc, day) => {
          return acc + day["duration"];
        }, 0);

        const counter = container.getElementsByClassName(
          "progress-sparkline-counter",
        )[0];
        const asHours = Math.round(duration / 3600);
        const suffix = asHours == 1 ? "hour" : "hours";

        counter.textContent = `${asHours} ${suffix}`;

        $("#in-code-editor").sparkline(
          data.map((day) => day["duration"]),
          sparklineConfig,
        );
      },
    );
  }
});
