import ace from "brace";

import "brace/mode/css";
import "brace/mode/html";
import "brace/mode/javascript";
import "brace/mode/python";
import "brace/mode/ruby";

import "brace/theme/katzenmilch";
import "brace/theme/pastel_on_dark";

export function initializeEditor(selector, lang, value, theme = "katzenmilch") {
  var editor = ace.edit(selector);

  // Disable CSS linter as it is outdated and does not support latest syntax.
  if (lang === "css") {
    editor.getSession().setAnnotations = function () {};
  }
  if (lang) {
    editor.getSession().setMode("ace/mode/" + lang);
  }

  editor.$blockScrolling = Infinity;
  editor.setTheme("ace/theme/" + theme);
  editor.getSession().setValue(value);
  editor.on("focus", () => document.dispatchEvent(new Event("editor:focus")));

  return editor;
}

export function initializeEditorFromTextarea(textarea) {
  var container = $("<div>", {
    position: "absolute",
    width: textarea.width(),
    height: textarea.height(),
    class: textarea.attr("class"),
  });
  var mode = textarea.data("language");
  var theme = textarea.data("theme") || "katzenmilch";
  var value = textarea.val();

  container.insertBefore(textarea);
  textarea.css("display", "none");

  const editor = initializeEditor(container[0], mode, value, theme);

  editor.getSession().on("change", () => {
    textarea.val(editor.getSession().getValue());
  });

  if (textarea.hasClass("ace-editor-readonly")) {
    editor.setReadOnly(true);
  }

  return editor;
}
