document.callOnReady(function () {
  var matchesFilter = function (props, filters) {
    if (
      filters.useName &&
      filters.name !== "" &&
      props.name.indexOf(filters.name) === -1
    ) {
      return false;
    }

    if (
      filters.useOther &&
      ((filters.subject !== "" &&
        $.inArray(filters.subject, props.subjects) === -1) ||
        (filters.language !== "" &&
          $.inArray(filters.language, props.languages) === -1))
    ) {
      return false;
    }

    return true;
  };

  var toggleCards = function (selector, filters) {
    var visible = 0;

    $(selector).each(function () {
      var props = {
        name: $(this).attr("data-name") || "",
        subjects: ($(this).attr("data-subjects") || "").split(" "),
        languages: ($(this).attr("data-languages") || "").split(" "),
      };

      var matches = matchesFilter(props, filters);
      $(this).toggle(matches);

      if (matches) {
        visible += 1;
      }
    });

    return visible;
  };

  var applyFilter = function () {
    var filters = {
      useName: $("#toggle-text-search").prop("checked"),
      name: $("#text-search").val(),
      useOther: $("#toggle-controls").prop("checked"),
      subject: $("input[name=subject]:checked").val() || "",
      language: $("input[name=language]:checked").val() || "",
    };

    var visibleTracks = toggleCards(
      ".track-card, .track-card-completed",
      filters,
    );
    var visibleCourses = toggleCards(".course-card", filters);

    $("#track-count").html(visibleTracks);
    $(".track-cards .no-results").toggle(visibleTracks === 0);
    $("#course-count").html(visibleCourses);
    $(".course-cards .no-results").toggle(visibleCourses === 0);
  };

  $(".container.tracks #toggle-text-search").on("change", function () {
    // Set focus on toggling text search.
    if (this.checked) {
      $("#text-search").focus();
    }
    applyFilter();
  });

  $(".container.tracks #text-search").on("keyup", function (event) {
    if (event.keyCode === 27) {
      $(this).val("");
    }
    applyFilter();
  });

  $(".container.tracks .filters input").on("change", applyFilter);

  // Toggle search controls visibility.
  $(".container.tracks #toggle-controls").on("click", function () {
    $(".search-controls-container").toggle();
    applyFilter();
  });

  // Clear filters button.
  $(".container.tracks #clear-filter").on("click", function () {
    $(".filters input").prop("checked", false);
    applyFilter();
  });

  // Run filter to recalculate initial counts of tracks and courses.
  applyFilter();
});
