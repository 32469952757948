// jQuery lives in a global space so all plugins need to be initialized here.
import $ from "lib/jquery";
import "jquery-ujs";

import "core-js";

import "bootstrap/dist/js/bootstrap"; // defines $.modal(), $.tab() and $.tooltip()
import "javascripts/github-contributions"; // defines $.github_graph()
import "jquery-sparkline"; // defines $.sparkline()
import "select2"; // defines $.select2()
import "!style-loader!css-loader!select2/dist/css/select2.css";

import "cocoon-js";

import LocalTime from "local-time";

LocalTime.start();

import "features/ace-editor";
import "features/daterange-filter";
import "features/guide-submissions";
import "features/guide_edit_requests";
import "features/notes";
import "features/public_profile";
import "features/quiz_scores";
import "features/tracks";
import "features/users_registrations";
import "features/video-player";

import { forEachElement } from "lib/document_helpers";

$(window).resize(function () {
  if ($(window).width() > 992) {
    $("#nav-modal").modal("hide");
  }
});

document.callOnReady(() => {
  $("[data-toggle='tooltip']").tooltip();

  forEachElement(".filters input", (element) => {
    element.addEventListener("click", () => {
      if (element.form) {
        element.form.submit();
      }
    });
  });
});
