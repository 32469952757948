document.callOnReady(function () {
  $(".copy-to-clipboard").click(function () {
    let target = $(this).data("target");

    $(this).tooltip({
      placement: "bottom",
      title: "Copied!",
      trigger: "manual",
    });

    $(this).tooltip("show");
    $(target).select();

    document.execCommand("copy");

    setTimeout(() => {
      $(this).tooltip("hide");
    }, 2000);
  });
});
