import { initializeEditorFromTextarea } from "lib/ace-editor";
import { staticEvent } from "lib/learning_analytics_helpers";

const checkSubmissionStatus = (
  submissionId,
  tries,
  submissionLoaded,
  analyticsPassEvent,
) => {
  $(".exercise-code #disabledSub .dots").html(
    ".".repeat(tries % 4) + "&nbsp;".repeat(3 - (tries % 4)),
  );

  $.get(
    "/submissions/" + submissionId,
    function (submission) {
      if (submission.status === "submitted") {
        delayedCheckSubmissionStatus(
          submissionId,
          tries + 1,
          submissionLoaded,
          analyticsPassEvent,
        );
      } else {
        if (submission.status == "passed") {
          document.dispatchEvent(analyticsPassEvent);
        }

        renderSubmission(submissionId, submission.status, submissionLoaded);
      }
    },
    "json",
  );
};

/* 4 tries with 2 second delay and 13 tries with 4 second delay is 60 seconds total. */
const delayFromSubmissionCheckTries = (tries) => {
  if (tries === 0) {
    return 0;
  } else if (tries <= 4) {
    return 2000;
  } else {
    return 4000;
  }
};

const delayedCheckSubmissionStatus = (
  submissionId,
  tries,
  submissionLoaded,
  analyticsPassEvent,
) => {
  if (tries > 17) {
    $(".exercise-code #disabledSub").hide();
    $(".exercise-code #timeoutSub").fadeIn();
  } else {
    window.setTimeout(function () {
      checkSubmissionStatus(
        submissionId,
        tries,
        submissionLoaded,
        analyticsPassEvent,
      );
    }, delayFromSubmissionCheckTries(tries));
  }
};

const displayResult = (result) => {
  $(".exercise-code #disabledSub").hide();
  $(".exercise-code #" + result + "Sub")
    .fadeIn(0)
    .delay(5000)
    .fadeOut(500);
};

const renderSubmission = (submissionId, submissionStatus, submissionLoaded) => {
  // This will get the partial and prepend it to the list of submissions.
  $.get("/submissions/" + submissionId + ".js", function () {
    displayResult(submissionStatus);
    submissionLoaded && submissionLoaded();
  });
};

document.callOnReady(() => {
  if ($(".exercise-code")) {
    const analyticsPassEvent = staticEvent("guide:submission:passed");

    $(".exercise-code .form-inline").on("ajax:send", function () {
      document.dispatchEvent(new Event("guide:submission:sent"));
      $(".exercise-code #disabledSub").show();
    });

    $(".exercise-code .form-inline").on(
      "ajax:success",
      function (event, response) {
        var hasGithubLink =
          response.content && response.content.hasOwnProperty("link");

        if (hasGithubLink) {
          renderSubmission(response.id, response.status);
        } else {
          delayedCheckSubmissionStatus(
            response.id,
            0,
            function () {
              $(
                "#submissions textarea.student-submission, #submissions textarea.student-submission-result",
              ).each(function () {
                if ($(this).siblings(".ace-editor").length == 0) {
                  initializeEditorFromTextarea($(this));
                }
              });
            },
            analyticsPassEvent,
          );
        }
      },
    );

    $(".exercise-code .form-inline").on(
      "ajax:error",
      function (event, response) {
        if (response.status === 401) {
          displayResult("unauthorized");
        } else {
          displayResult("exception");
        }
      },
    );
  }
});
