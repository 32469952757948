const eventToType = (event) => {
  switch (event.type) {
    case "DOMContentLoaded":
      return "page view";
    case "activity:ping":
      return "page activity ping";
    case "beforeunload":
      return "page closed";
    case "editor:focus":
      return "start editing code";
    case "guide:submission:passed":
      return "passed code submission";
    case "guide:submission:sent":
      return "submitted code";
    case "pagefocus":
      return "page focus";
    case "pageoutoffocus":
      return "page out of focus";
    case "quiz:started":
      return "quiz started";
    case "quiz:submitted":
      return "quiz submitted";
    case "turbolinks:load":
      return "page view";
    case "videojs:end":
      return "video stopped";
    case "videojs:interrupted":
      return "video stopped";
    case "videojs:pause":
      return "video stopped";
    case "videojs:play":
      return "video started";
    default:
      return event.type;
  }
};

export const eventToParams = (event) => {
  const epoch = Math.round(Date.now() / 1000);
  const params = Array.from(
    document.querySelectorAll(".analytics-param"),
  ).reduce(
    (a, e) => Object.assign(a, { [e.dataset.key]: e.dataset.value }),
    {},
  );

  return {
    ...params,
    epoch,
    type: eventToType(event),
  };
};

export const staticEvent = (type) => {
  return new CustomEvent(type, {
    detail: eventToParams(new Event(type)),
  });
};
