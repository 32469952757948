import * as Diff2Html from "diff2html";
import "!style-loader!css-loader!diff2html/bundles/css/diff2html.min.css";

document.callOnReady(() => {
  let diffElements = document.querySelectorAll(".diff");

  diffElements.forEach(function (element) {
    let output = Diff2Html.html(element.dataset.diff, {
      drawFileList: false,
      outputFormat: "side-by-side",
    });
    element.innerHTML = output;
  });
});
