import Litepicker from "litepicker";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);

import "litepicker/dist/plugins/ranges";

const formatTimeframe = (after, before) => {
  const format = "YYYY-MM-DD";
  const formattedAfter = after.format(format);
  const formattedBefore = before.format(format);

  if (formattedAfter == formattedBefore) {
    return formattedAfter;
  } else {
    return `${formattedAfter} - ${formattedBefore}`;
  }
};

document.callOnReady(() => {
  const selector = ".daterange-filter";
  const daterange = document.querySelector(selector);
  const form = $(daterange).closest("form");

  if (daterange && form) {
    const after = daterange.parentNode.querySelector("input[name='after']");
    const before = daterange.parentNode.querySelector("input[name='before']");
    const timeframe = daterange.parentNode.querySelector(".timeframe");

    const startDate = after && after != "" ? dayjs(after.value) : null;
    const endDate = before && before != "" ? dayjs(before.value) : null;

    new Litepicker({
      element: timeframe || daterange,
      endDate,
      maxDate: dayjs(),
      ranges: {
        position: "left",
        customRanges: {
          Today: [dayjs().toDate(), dayjs().toDate()],
          Yesterday: [
            dayjs().subtract(1, "days").toDate(),
            dayjs().subtract(1, "days").toDate(),
          ],
          "This week": [
            dayjs().startOf("week").toDate(),
            dayjs().endOf("week").toDate(),
          ],
          "Last week": [
            dayjs().subtract(1, "week").startOf("week").toDate(),
            dayjs().subtract(1, "week").endOf("week").toDate(),
          ],
          "This month": [
            dayjs().startOf("month").toDate(),
            dayjs().endOf("month").toDate(),
          ],
          "Last month": [
            dayjs().subtract(1, "month").startOf("month").toDate(),
            dayjs().subtract(1, "month").endOf("month").toDate(),
          ],
          "This quarter": [
            dayjs().startOf("quarter").toDate(),
            dayjs().toDate(),
          ],
          "Last quarter": [
            dayjs().subtract(1, "quarter").startOf("quarter").toDate(),
            dayjs().subtract(1, "quarter").endOf("quarter").toDate(),
          ],
          "Past 3 months": [
            dayjs().subtract(3, "month").startOf("month").toDate(),
            dayjs().subtract(1, "month").endOf("month").toDate(),
          ],
          "Past 6 months": [
            dayjs().subtract(6, "month").startOf("month").toDate(),
            dayjs().subtract(1, "month").endOf("month").toDate(),
          ],
        },
      },
      numberOfColumns: 1,
      numberOfMonths: 1,
      plugins: ["ranges"],
      resetButton: true,
      setup: (picker) => {
        picker.on("clear:selection", () => {
          after.value = "";
          before.value = "";
          form.submit();
        });

        picker.on("selected", (start, end) => {
          after.value = dayjs(start.toJSDate()).startOf("day").format();
          before.value = dayjs(end.toJSDate()).endOf("day").format();
          form.submit();
        });
      },
      singleMode: false,
      startDate: startDate,
    });

    if (timeframe && startDate && endDate) {
      timeframe.textContent = formatTimeframe(startDate, endDate);
    }
  }
});
