import videojs from "video.js";
import "!style-loader!css-loader!video.js/dist/video-js.css";

import { trackError } from "lib/appsignal";
import debounce from "lib/debounce";

window.HELP_IMPROVE_VIDEOJS = false;

const VALID_PLAYBACK_RATES = [1, 1.25, 1.5, 1.75, 2];

class VideoPlayerError extends Error {
  constructor(message, code) {
    super(`${message} (code: ${code})`);
    this.name = "VideoPlayerError";
  }
}

function initializeVideoPlayer(selectorId) {
  const findActiveSubtitlesLanguage = function (tracks) {
    const active = tracks.find(function (track) {
      return track.mode == "showing";
    });

    return active == null ? null : active.language;
  };

  const playerOptions = {
    fluid: true,
    playbackRates: VALID_PLAYBACK_RATES,
    poster: document.getElementById("video").dataset.posterUrl,
    preload: "metadata",
  };

  const player = videojs(selectorId, playerOptions, function () {
    this.on("error", () => {
      // Default videojs player's errors do not inherit from Error class.
      const error = this.error();

      trackError(new VideoPlayerError(error.message, error.code), {
        current_source: this.currentSource(),
        current_time: this.currentTime(),
        remaining_time: this.remainingTime(),
        text_tracks: this.textTracks(),
        video_tracks: this.videoTracks(),
      });
    });

    this.playbackRate(document.videoPlayerPlaybackRate);

    this.on("ended", () => document.dispatchEvent(new Event("videojs:end")));
    this.on("pause", () => document.dispatchEvent(new Event("videojs:pause")));
    this.on("play", () => document.dispatchEvent(new Event("videojs:play")));

    this.on("dispose", () => {
      if (!this.ended() && !this.paused() && this.currentTime() > 0) {
        document.dispatchEvent(new Event("videojs:interrupted"));
      }
    });

    this.on("ratechange", function () {
      const video_playback_rate = this.playbackRate();

      // `videoPlayerPlaybackRate` is set in the view depending on user's settings.
      if (
        document.videoPlayerPlaybackRate != video_playback_rate &&
        VALID_PLAYBACK_RATES.includes(video_playback_rate)
      ) {
        $.ajax({
          type: "PATCH",
          url: "/current_user",
          data: JSON.stringify({ user: { video_playback_rate } }),
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: function () {
            document.videoPlayerPlaybackRate = video_playback_rate;
          },
        });
      }
    });

    // debounce is necessary because `change` event will be fired for each `track`
    // element. As the result, the following handler would be called multiple times.
    this.textTracks_.addEventListener(
      "change",
      debounce(function () {
        const subtitles_language = findActiveSubtitlesLanguage(this.tracks_);

        // `videoPlayerSubtitlesLanguage` is set in the view depending on user's
        // settings
        if (document.videoPlayerSubtitlesLanguage != subtitles_language) {
          $.ajax({
            type: "PATCH",
            url: "/current_user",
            data: JSON.stringify({ user: { subtitles_language } }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function () {
              document.videoPlayerSubtitlesLanguage = subtitles_language;
            },
          });
        }
      }, 250),
    );
  });
}

document.callOnReady(() => {
  if (document.getElementById("video")) {
    initializeVideoPlayer("video");
  }
});

document.callOnPageChange(() => {
  const element = document.getElementById("video");

  if (element && element.player) {
    element.player.dispose();
  }
});
